.sidebar-kyc {
    position: fixed;
    width: 290px;
    height: 100%;
    left: 0px;
    top: 0px;
    background: #1D2833;
    padding: 36px 0;
    height: 100vh;
    overflow: auto;
}

.sidebar-kyc .logo {
    margin-bottom: 62px;
    margin-left: 24px;
    color: #fff;
    font-weight: 500;
}

.sidebar-kyc .logo img{
    margin-right: 0.75rem;
}

.sidebar-kyc .nav-tabs {
    border-bottom: none;
    flex-direction: column;
}

.sidebar-kyc .nav-tabs .nav-link {
    border: none;
    border-radius: 0;
    margin-bottom: 0;
    width: 100%;
    text-align: left;
    display: flex;
    align-items: center;
    gap: 17px;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    color: #fff !important;
    height: 50px;
    padding: 8px 12px;
    margin-bottom: 10px;
    transition: all ease-in-out 0.2s;
    border-radius: 5px;
}
.sidebar-kyc .nav-tabs .nav-item{
    margin:0 12px;
}
.sidebar-kyc .nav-tabs .nav-item.show .nav-link,
.sidebar-kyc .nav-tabs .nav-link.active,
.sidebar-kyc .nav-tabs .nav-link:hover {
    background: rgba(255, 255, 255, 0.05);
}


.sidebar-kyc .user {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #C0C4D3;
    display: flex;
    align-items: center;
    gap: 17px;
    padding: 0 24px;
    position: absolute;
    bottom: 48px;
    left: 0;
    width: 100%;
}

.sidebar-kyc .user img {
    width: 24px;
    height: 24px;
    border-radius: 4px;
}

.sidebar-kyc .nav-tabs .nav-item.subtabs-head ~ li.subtab button{
    background: transparent !important;
    padding: 0;
    height: auto;
    padding-left: 40px;
}

.sidebar-kyc .nav-tabs .nav-item.subtabs-head ~ li.subtab button img.active-icon{
    display: none;
}

.sidebar-kyc .nav-tabs .nav-item.subtabs-head ~ li.subtab.show button img,
.sidebar-kyc .nav-tabs .nav-item.subtabs-head ~ li.subtab button.active img,
.sidebar-kyc .nav-tabs .nav-item.subtabs-head ~ li.subtab button:hover img{
    display: none;
}

.sidebar-kyc .nav-tabs .nav-item.subtabs-head ~ li.subtab.show button img.active-icon,
.sidebar-kyc .nav-tabs .nav-item.subtabs-head ~ li.subtab button.active img.active-icon,
.sidebar-kyc .nav-tabs .nav-item.subtabs-head ~ li.subtab button:hover img.active-icon{
    display: inline-block;
}