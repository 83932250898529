

/* fonts css end */

/* global css start */
* {
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
  background: #161D23;
}

a {
  display: inline-block;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

ul {
  padding: 0;
  list-style-type: none;
}

figure {
  margin: 0;
}

img {
  max-width: 100%;
  height: auto;
}

/* global css end */